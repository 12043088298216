import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import TreeVisualization from './TreeVisualization';
import ChatWindow from './ChatWindow';
import Navbar from './Navbar';
import axios from "axios";

function FlowBuilder() {
  const [jsonData, setJsonData] = useState({});
  const [messages, setMessages] = useState([]); // State for chat messages
  const treeContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    fetchJsonData();
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    if (treeContainerRef.current) {
      resizeObserver.observe(treeContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const fetchJsonData = async (message = '') => {
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CHATBOT_API_URL}/v1/setup/flow-build`,
        {
          userInput: message
        }
      );
      setJsonData(response?.data?.data?.flow);
    } catch (error) {
      console.error("Error creating flow:", error.response || error);
    }
  };

  const handleSend = (message) => {
    setMessages([...messages, message]); // Add new message to chat history
    fetchJsonData(message);
  };

  const handleUndo = () => {
    setMessages(messages.slice(0, -1)); // Remove the last message
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Navbar />
      <Box sx={{ display: 'flex', height: '95vh' }}>
        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
          <Paper elevation={1} sx={{ flex: 1, overflow: 'auto', p: 2, m: 2, backgroundColor: '#f5f5f5' }}>
            <Typography variant="h6">Flow Data</Typography>
            <pre>{JSON.stringify(jsonData, null, 2)}</pre>
          </Paper>
          <Box sx={{ flex: 1, p: 2 }}>
            <ChatWindow
              messages={messages}
              onSend={handleSend}
              onUndo={handleUndo}
            />
          </Box>
        </Box>
        <Box sx={{ width: '50%', p: 2 }} ref={treeContainerRef}>
          <TreeVisualization key={containerWidth+jsonData} data={jsonData} _width={containerWidth - 270} />
        </Box>
      </Box>
    </Box>
  );
}

export default FlowBuilder;
