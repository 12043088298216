import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const pages = ["Business-Info", "Flows", "Campaigns", "Logout"];
const pageUrls = ["/", "/flows", "/campaigns", "/logout"];

function Navbar() {
  return (
    <AppBar position="static" sx={{ mt: 1 }}>
      <Container
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        {pages.map((page, index) => (
          <Button key={page}> 
            <Link to={pageUrls[index]} style={{ textDecoration: "none" }}>
              <Typography sx={{ color: "white" }}>
                {page}
              </Typography>
            </Link>
          </Button>
        ))}
      </Container>
    </AppBar>
  );
}
export default Navbar;
