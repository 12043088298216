import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout({ setBusiness }) {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("business");
    setBusiness(null);
    navigate("/");
  }, [navigate, setBusiness]);

  return <div>Logging out</div>;
}

export default Logout;
