import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Navbar from './Navbar';

function Profile({ business }) {
  return (
		   <> <Navbar />
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <VerifiedUser />
          </Avatar>
          <Typography component="h1" variant="h5">
            Business Info
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
			{
				Object.keys(business).map((key, index) => {
					if(key === 'flows'){
						return null;
					}
					return <TextField
					margin="normal"
					multiline
					fullWidth
					id={key}
					label={key}
					name={key}
					key={index}
					value={business[key] || ""}
					aria-readonly
				  />
				})
			}
          </Box>
        </Box>
      </Container></>
  )
}

export default Profile