import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import SignUp from "./components/Signup";
import Flows from "./components/Flows";
import Profile from "./components/Profile";
import Logout from "./components/Logout";
import Campaigns from "./components/Campaigns";
import FlowBuilder from "./components/FlowBuilder";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const defaultTheme = createTheme();

function App() {
  const [business, setBusiness] = useState(null);
    useEffect(() => {
    const storedBusiness = localStorage.getItem("business");
    if (storedBusiness) {
      setBusiness(JSON.parse(storedBusiness));
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Routes>
          {business ? (
            <>
              <Route path="/" element={<Profile business={business} />} />
              <Route path="/flows" element={<Flows business={business} />} />
              <Route path="/flow-builder" element={<FlowBuilder />} /> 
              <Route path="/logout" element={<Logout setBusiness={setBusiness} />} />
              <Route path="/campaigns" element={<Campaigns business={business} />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login setBusiness={setBusiness} />} />
              <Route path="/signup" element={<SignUp />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
