  import React, { useState, useEffect } from 'react';
  import { Link } from 'react-router-dom'; 
  import { Box, Button, Typography, Modal, TextField, Tabs, Tab, Container } from '@mui/material';
  import Flow from './Flow';
  import Navbar from './Navbar'; 
  import TreeVisualization from './TreeVisualization'; 

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  function Flows({ business }) {
    const [businessState, setBusinessState] = useState(() => {
      const savedBusiness = localStorage.getItem('business');
      return savedBusiness ? JSON.parse(savedBusiness) : business;
    });
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [open, setOpen] = useState(false);
    const [newFlowName, setNewFlowName] = useState('');
    const [newFlowDescription, setNewFlowDescription] = useState('');
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
      localStorage.setItem('business', JSON.stringify(businessState));
    }, [businessState]);

    for(let i=0; i<businessState.flows.length; i++)
      businessState.flows[i].id = business.flows[i]._id;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      setNewFlowName("");
      setNewFlowDescription("");
    };

    const handleCreateFlow = () => {
      if (!newFlowName || !newFlowDescription) {
        return;
      }

      const newFlow = {
        phoneNumberId: business.phoneNumberId,
        name: newFlowName,
        content: [],
        description: newFlowDescription,
        id: `new-${Date.now()}`,
        isNew: true,
      };

      handleClose();
      setSelectedFlow(newFlow);
      setTabValue(0);
      setNewFlowName("");
      setNewFlowDescription("");
    };

    const handleFlowSelect = (flow) => setSelectedFlow(flow);

    const handleFlowSave = (flowData) => {
      const updatedFlows = businessState.flows.map((f) =>
        f.id === flowData.id ? flowData : f
      );

      if (!updatedFlows.find((f) => f.id === flowData.id)) {
        updatedFlows.push(flowData);
      }

      setBusinessState({ ...businessState, flows: updatedFlows });
      setSelectedFlow(flowData);
    };

    const handleFlowDelete = (flowId) => {
      const updatedFlows = businessState.flows.filter((f) => f.id !== flowId);
      setBusinessState({ ...businessState, flows: updatedFlows });
      setSelectedFlow(null);
    };

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Navbar />

        <Container sx={{ mt: 4, mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {businessState.flows.map((flow) => (
              <Button
                key={flow.id}
                onClick={() => handleFlowSelect(flow)}
                sx={{
                  backgroundColor:
                    selectedFlow && flow.id === selectedFlow.id
                      ? "primary.dark"
                      : "white",
                  color:
                    selectedFlow && flow.id === selectedFlow.id
                      ? "white"
                      : "primary.dark",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "white",
                  },
                  margin: "6px",
                  m: 1,
                }}
                variant="contained"
              >
                {flow.name}
              </Button>
            ))}
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button onClick={handleOpen} sx={{ bgcolor: "green", '&:hover': { bgcolor: "darkgreen" } }} variant="contained">
              + Create New Flow
            </Button>
            <Link to="/flow-builder" style={{ textDecoration: 'none' }}>
              <Button sx={{ bgcolor: "primary", '&:hover': { bgcolor: "darkblue" } }} variant="contained">
                Build Flow
              </Button>
            </Link>
          </Box>
        </Container>

        {selectedFlow && <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} centered>
          <Tab label="Manage Flows" />
          <Tab label="Visualize Flows" />
        </Tabs>}

        {tabValue === 0 && selectedFlow && (
          <Flow key={selectedFlow.id} flow={selectedFlow} onSave={handleFlowSave} onDelete={handleFlowDelete} />
        )}

        {tabValue === 1 && selectedFlow && <TreeVisualization data={business.flows}/>}

        <Modal open={open} onClose={handleClose}>
          <Box sx={{ ...style, borderRadius: 2 }}>
            <Typography variant="h6">Enter new Flow details:</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Flow Name"
              type="text"
              fullWidth
              variant="outlined"
              value={newFlowName}
              onChange={(e) => setNewFlowName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="description"
              label="Flow Description"
              type="text"
              fullWidth
              variant="outlined"
              value={newFlowDescription}
              onChange={(e) => setNewFlowDescription(e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button onClick={handleCreateFlow} color="primary" variant="contained">
                Create
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }

  export default Flows;
