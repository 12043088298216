import React, { useState } from 'react';
import { Typography, Container, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Navbar from './Navbar';
import axios from "axios";

function Campaigns({ business }) {

  for(let i=0; i<business.flows.length; i++)
    business.flows[i].id = business.flows[i]._id;

  const [selectedFlow, setSelectedFlow] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState(['']);

  const handleFlowChange = (event) => {
    setSelectedFlow(event.target.value);
  };

  const handlePhoneNumberChange = (index, event) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index] = event.target.value;
    setPhoneNumbers(newPhoneNumbers);
  };

  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, '']);
  };

  const removePhoneNumber = (index) => {
    const newPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
    setPhoneNumbers(newPhoneNumbers);
  };

  const handleCreateCampaign = async ()  => {
    console.log('Creating campaign with phone numbers:', phoneNumbers);

    try {
        await axios.put(
          `${process.env.REACT_APP_CHATBOT_API_URL}/v1/setup/flow/${selectedFlow}/trigger/`,
          {
            phoneNumberId: business.phoneNumberId,
            clientWaNumber: phoneNumbers,
          }
        );
      } catch (error) {
        console.error("Error creating campaign:", error.response || error);
      }

  };

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Campaigns
        </Typography>
        <Box sx={{ mb: 3 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-flow-label">Select Flow</InputLabel>
            <Select
              labelId="select-flow-label"
              id="select-flow"
              value={selectedFlow}
              label="Select Flow"
              onChange={handleFlowChange}
            >
              {business.flows.map((flow) => (
                <MenuItem key={flow.id} value={flow.id}>{flow.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Typography variant="h6" gutterBottom>
          Phone Numbers
        </Typography>
        {phoneNumbers.map((phoneNumber, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <TextField
              fullWidth
              label={`Phone Number ${index + 1}`}
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(index, e)}
            />
            <IconButton onClick={() => removePhoneNumber(index)} color="error" aria-label="remove phone number">
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 3 }}>
          <IconButton onClick={addPhoneNumber} color="primary" aria-label="add phone number">
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleCreateCampaign}
            sx={{ mb: 5}}
          >
            Create Campaign
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default Campaigns;
