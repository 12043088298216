import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import UndoIcon from '@mui/icons-material/Undo';

function ChatWindow({ messages, onSend, onUndo }) {
  const [inputText, setInputText] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleInputChange = (e) => setInputText(e.target.value);

  const handleSendClick = () => {
    if (!inputText.trim()) return;
    onSend(inputText);
    setInputText('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendClick();
    }
  };

  return (
    <Paper elevation={3} sx={{ maxHeight: '100%',minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flex: 1, overflow: 'auto', maxHeight: '400px', p: 1 }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem key={index} sx={{ 
                margin: '5px', 
                padding: '10px', 
                borderRadius: '10px', 
                border: '1px solid #e0e0e0', 
                backgroundColor: '#f9f9f9',
                wordBreak: 'break-word'
            }}>
              <ListItemText primary={msg} />
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Box>
      <Box sx={{ borderTop: 1, borderColor: 'divider', p: 1, display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          variant="outlined"
          placeholder="Type a message..."
          value={inputText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          size="small"
        />
        <IconButton onClick={handleSendClick} color="primary">
          <SendIcon />
        </IconButton>
        <IconButton onClick={onUndo} color="secondary">
          <UndoIcon />
        </IconButton>
      </Box>
    </Paper>
  );
}

export default ChatWindow;
