import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  Fade,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import JsonEditor from "react-json-editor-ui";
import axios from "axios";

function Flow({ flow, onSave, onDelete }) {
  const [name, setName] = useState(flow.name || "");
  const [description, setDescription] = useState(flow.description || "");
  const [content, setContent] = useState(flow.content || []);
  const [isEdited, setIsEdited] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    setName(flow.name || "");
    setDescription(flow.description || "");
    setContent(flow.content || []);
    setIsEdited(false);
  }, [flow]);

  const handleEditorChange = (newContent) => {
    setContent(newContent);
    setIsEdited(true);
  };

  const handleCreateOrUpdate = async () => {
    const contentArray = [];
    for(let key of content)    contentArray.push(content[key]);

    if (flow.isNew) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_CHATBOT_API_URL}/v1/setup/flow`,
          {
            phoneNumberId: flow.phoneNumberId,
            flows: [
              {
                name,
                content: contentArray,
                description,
              },
            ],
          }
        );
        onSave({
          ...flow,
          name,
          description,
          content: contentArray,
          id: response.data.data.ids[0],
          isNew: false,
        });
      } catch (error) {
        console.error("Error creating flow:", error.response || error);
      }
    } else {
      if (!isEdited) {
        return;
      }
      try {
        await axios.put(
          `${process.env.REACT_APP_CHATBOT_API_URL}/v1/setup/flow/${flow.id}`,
          {
            name,
            content: contentArray,
            description,
          }
        );
        onSave({ ...flow, name, description, content: contentArray });
        setIsEdited(false);
      } catch (error) {
        console.error("Error updating flow:", error.response || error);
      }
    }
  };

  const handleDelete = () => {
    setOpenConfirmationModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_CHATBOT_API_URL}/v1/setup/flow/${flow.id}`
      );
      onDelete(flow.id);
      setIsEdited(false);
    } catch (error) {
      console.error("Error deleting flow:", error.response || error);
    } finally {
      setOpenConfirmationModal(false);
    }
  };

  const cancelDelete = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 3,
        minWidth: "70%",
      }}
    >
      {flow.isNew && (
        <Typography component="h1" variant="h5">
          Create New Flow
        </Typography>
      )}

      <Accordion
        sx={{ width: '100%', mt: 2, boxShadow: 3 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="name-content"
          id="name-header"
          sx={{ background: "#F5F5F5", borderBottom: "1px solid #E0E0E0" }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Flow Name
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            margin="dense"
            id="name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setIsEdited(true);
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          width: "100%",
          mt: 2,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="description-content"
          id="description-header"
          sx={{ background: "#F5F5F5", borderBottom: "1px solid #E0E0E0" }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Flow Description
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            margin="dense"
            id="description"
            type="text"
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setIsEdited(true);
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          width: "100%",
          mt: 2,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="content"
          id="content-header"
          sx={{ background: "#F5F5F5", borderBottom: "1px solid #E0E0E0" }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Flow Content
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <JsonEditor data={content} onChange={handleEditorChange} />
        </AccordionDetails>
      </Accordion>

      <Button
        onClick={handleCreateOrUpdate}
        color="primary"
        variant="contained"
        sx={{ mt: "10px" }}
        disabled={!isEdited}
      >
        {flow.isNew ? "Create" : "Update"}
      </Button>
      {!flow.isNew && (
        <>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            sx={{ mt: "10px" }}
          >
            Delete
          </Button>
          <Modal
            open={openConfirmationModal}
            onClose={cancelDelete}
            aria-labelledby="delete-flow-modal-title"
            aria-describedby="delete-flow-modal-description"
            closeAfterTransition
          >
            <Fade in={openConfirmationModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "white",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Typography
                  id="delete-flow-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ mb: 3, fontWeight: "bold", color: "error.main" }}
                >
                  Confirm Deletion
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ mb: 3, color: "text.secondary" }}
                >
                  Are you sure you want to delete this flow? This action cannot
                  be undone.
                </Typography>
                <Button
                  onClick={confirmDelete}
                  color="error"
                  variant="contained"
                  sx={{ mr: 2 }}
                >
                  Yes, Delete
                </Button>
                <Button
                  onClick={cancelDelete}
                  color="primary"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </Box>
  );
}

export default Flow;