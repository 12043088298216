import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const TreeVisualization = ({ data, _width }) => {
  const d3Container = useRef(null);

  const wrap = (text, width) => {
    text.each(function () {
      const text = d3.select(this);
      let words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let y = text.attr("y");
      let dy = parseFloat(text.attr("dy"));
      let tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", 1 + dy + "em")
            .text(word);
        }
      }
    });
  };

  useEffect(() => {
    if (data && d3Container.current) {
      const margin = { top: 40, right: 120, bottom: 40, left: 120 };
      const width = _width ?? 1200;
      const height = 800 - margin.top - margin.bottom - 10;

      d3.select(d3Container.current).selectAll("*").remove();

      // const tooltip = d3
      //   .select(d3Container.current)
      //   .append("div")
      //   .attr("class", "tooltip")
      //   .style("opacity", 0);

      const zoomBehavior = d3
        .zoom()
        .scaleExtent([0.1, 4]) // Set the scale limits here
        .on("zoom", (event) => {
          svgGroup.attr("transform", event.transform);
        });

      const svg = d3
        .select(d3Container.current)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .call(zoomBehavior)
        .append("g");

      const defs = svg.append("defs");
      const filter = defs
        .append("filter")
        .attr("id", "drop-shadow")
        .attr("height", "130%");
      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", 3)
        .attr("result", "blur");
      filter
        .append("feOffset")
        .attr("in", "blur")
        .attr("dx", 2)
        .attr("dy", 2)
        .attr("result", "offsetBlur");
      const feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      svg
        .append("rect")
        .attr("width", width + margin.left + margin.right - 4)
        .attr("height", height + margin.top + margin.bottom - 4)
        .attr("fill", "none")
        .attr("stroke", "black")
        .attr("stroke-width", 2)
        .attr("x", 2)
        .attr("y", 2);

      const svgGroup = svg
        .append("g")
        .attr("transform", "translate(" + (width / 2 + 120) + "," + 100 + ")");

      let stratifyData = [];

      stratifyData.push({
        id: 0,
        parentId: null,
        // name: "Hi 👋, I'm LaundryMate's AI-powered virtual assistant, who can take the laundry load off your hands. What can I help you with today?",
        name: "Hi 👋, I'm an AI-powered virtual assistant. What can I help you with today?",
      });

      let temp = 1;

      let addLinks = [];

      for (let i = 0; i < data.length; i++) {

        if(!(data[i].name || data[i].message?.text || data[i].api_call?.description))   continue;

        stratifyData.push({
          id: temp,
          parentId: 0,
          name: data[i].name || data[i].message?.text || data[i].api_call?.description,
        });

        let par = [temp];

        for (let j = 0; j < data[i].content?.length; j++) {
          let check = data[i].content[j].name === "Main Menu";

          if (
            data[i].content[j]?.message?.type === "text" &&
            data[i].content[j]?.message?.text
          ) {
            stratifyData.push({
              id: temp + 1,
              parentId: par[Math.floor(par.length / 2)],
              name: data[i].content[j]?.message?.text,
            });

            temp++;
            for (let k = 0; k < par.length; k++) {
              if (k === Math.floor(par.length / 2)) continue;
              addLinks.push([par[k], temp]);
            }
            par = [temp];
          }

          if (
            data[i].content[j]?.message?.type === "interactive" &&
            data[i].content[j]?.message?.interactive?.body?.text
          ) {
            stratifyData.push({
              id: temp + 1,
              parentId: par[Math.floor(par.length / 2)],
              name: data[i].content[j]?.message?.interactive?.body?.text,
            });

            temp++;

            for (let k = 0; k < par.length; k++) {
              if (k === Math.floor(par.length / 2)) continue;
              addLinks.push([par[k], temp]);
            }

            if (check) {
              par = [temp];
              continue;
            }

            if (data[i].content[j]?.message?.interactive?.type === "list") {
              let _temp = temp;
              par = [];

              for (
                let k = 0;
                k <
                data[i].content[j]?.message?.interactive?.action?.sections[0]
                  ?.rows?.length;
                k++
              ) {
                stratifyData.push({
                  id: temp + 1,
                  parentId: _temp,
                  name: data[i].content[j]?.message?.interactive?.action
                    ?.sections[0]?.rows[k].title,
                });

                temp++;
                par.push(temp);
              }

              temp++;
              continue;
            }

            let _temp = temp;
            par = [];

            for (
              let k = 0;
              k <
              data[i].content[j]?.message?.interactive?.action?.buttons?.length;
              k++
            ) {
              stratifyData.push({
                id: temp + 1,
                parentId: _temp,
                name: data[i].content[j]?.message?.interactive?.action?.buttons[
                  k
                ]?.reply?.title,
              });

              temp++;

              par.push(temp);
            }

            temp++;
          }
        }

        temp++;
      }

      const root = d3
        .stratify()
        .id((d) => d.id)
        .parentId((d) => d.parentId)(stratifyData);

      const nodeWidth = 300;
      const verticalSpacing = 250;
      const treeLayout = d3.tree().nodeSize([nodeWidth, verticalSpacing]);

      treeLayout.separation((a, b) => {
        return a.parent === b.parent ? 1 : 1.3;
      });

      treeLayout(root);
      treeLayout(root);

      svgGroup
        .selectAll(".link")
        .data(root.links())
        .enter()
        .append("path")
        .attr("class", "link")
        .attr("d", (d) => {
          const targetY = d.target.y - 40;
          return `M${d.source.x},${d.source.y}C${d.source.x},${
            (d.source.y + targetY) / 2
          } ${d.target.x},${(d.source.y + targetY) / 2} ${
            d.target.x
          },${targetY}`;
        })
        .attr("stroke", "#ccc")
        .attr("fill", "none");

      let additionalLinks = addLinks
        .map((linkPair) => {
          const sourceNode = root
            .descendants()
            .find((node) => node.id === linkPair[0].toString());
          const targetNode = root
            .descendants()
            .find((node) => node.id === linkPair[1].toString());
          return sourceNode && targetNode
            ? { source: sourceNode, target: targetNode }
            : null;
        })
        .filter((link) => link !== null);

      svgGroup
        .selectAll(".additional-link")
        .data(additionalLinks)
        .enter()
        .append("path")
        .attr("class", "link")
        .attr("d", (d) => {
          const targetY = d.target.y - 40;
          return `M${d.source.x},${d.source.y}C${d.source.x},${
            (d.source.y + targetY) / 2
          } ${d.target.x},${(d.source.y + targetY) / 2} ${
            d.target.x
          },${targetY}`;
        })
        .attr("stroke", "#ccc")
        .attr("fill", "none");

      const node = svgGroup
        .selectAll(".node")
        .data(root.descendants())
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", (d) => "translate(" + d.x + "," + d.y + ")");

      const nodeText = node
        .append("text")
        .attr("dy", "0.2em")
        .attr("text-anchor", "middle")
        .text((d) => d.data.name)
        .call(wrap, 280);

      nodeText.each(function (d) {
        const bbox = this.getBBox();
        d.bbox = {
          width: Math.max(120, bbox.width + 20),
          height: bbox.height + 10,
        };
      });

      const paddingX = 10;
      const paddingY = 20;

      node
        .insert("rect", "text")
        .attr("width", (d) => d.bbox.width + paddingX * 2)
        .attr("height", (d) => d.bbox.height + paddingY * 2)
        .attr("x", (d) => -(d.bbox.width + paddingX * 2) / 2)
        .attr("y", (d) => -(d.bbox.height + paddingY * 2) / 2)
        .attr("stroke", "steelblue")
        .attr("fill", "#fff")
        .style("filter", "url(#drop-shadow)")
        .attr("rx", 10)
        .attr("ry", 10);

      node.selectAll("text").attr("y", (d) => -d.bbox.height / 2 + paddingY);

      const arrowheadPath = "M0,0 L10,10 L20,0 L0,0"; 

      node.each(function (d, i) {
        if (i !== 0) { 
          const bbox = this.getBBox();
          const arrowX = bbox.x + bbox.width / 2 - 10; 
          const arrowY = bbox.y - 11;
      
          d3.select(this)
            .append("path")
            .attr("d", arrowheadPath)
            .attr("transform", `translate(${arrowX}, ${arrowY})`)
            .attr("fill", "black");
        }
      });

      node
        .on("mouseover", function (event, d) {
          d3.select(this)
            .select("rect")
            .transition()
            .duration(100)
            .attr("fill", "#e6f7ff")
            .attr("stroke", "orange");
          // let tooltipContent = "";
          // for (const key in d.data) {
          //   tooltipContent += `<strong>${key}:</strong> ${d.data[key]}<br/>`;
          // }

          // tooltip.transition().duration(200).style("opacity", 0.9);
          // tooltip
          //   .html(tooltipContent)
          //   .style("left", event.pageX + 10 + "px")
          //   .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", function (event, d) {
          d3.select(this)
            .select("rect")
            .transition()
            .duration(100)
            .attr("fill", "#fff")
            .attr("stroke", "steelblue");

          // tooltip.transition().duration(500).style("opacity", 0);
        });
    }
  }, [_width, data]);

  return (
    <div>
      {/* <style>
        {`
        .tooltip {
          position: absolute;
          text-align: left; 
          width: auto; 
          padding: 8px;
          font: 12px sans-serif;
          background: lightsteelblue;
          border: 0px;
          border-radius: 8px;
          pointer-events: none;
          white-space: nowrap;
          overflow: hidden; 
        }
      `}
      </style> */}
      <div
        className="d3-component"
        ref={d3Container}
        style={{
          width: "100%",
          height: "850px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      />
    </div>
  );
};

export default TreeVisualization;
