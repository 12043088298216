import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SignInIcon from '@mui/icons-material/VpnKeyOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function SignUp() {
  const [keywords, setKeywords] = React.useState([]);
  const [keywordInput, setKeywordInput] = React.useState('');
  const navigate = useNavigate();

  const handleDeleteChip = (chipToDelete) => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && keywordInput) {
      event.preventDefault(); 
      if (!keywords.includes(keywordInput)) {
        setKeywords([...keywords, keywordInput]);
        setKeywordInput('');
      }
    }
  };

  const handleChangeInput = (event) => {
    setKeywordInput(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
  
    const obj = {
      name: formData.get('name'),
      phoneNumberId: formData.get('phoneNumberId'),
      apiKey: formData.get('apiKey'),
      chatbotIns: formData.get('chatbotInstructions'),
      keywords: keywords
    }
  
    const apiURL = process.env.REACT_APP_CHATBOT_API_URL + "/v1/setup/business/signup";
  
    try {
      const response = await axios.post(apiURL, obj);
      console.log('response.data', response.data);
      navigate('/');
    } catch (error) {
      console.error('There was an error!', error.response);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          justifyContent: 'flex-start',
          paddingTop: 8,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <SignInIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phoneNumberId"
            label="Phone Number ID"
            name="phoneNumberId"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="apiKey"
            label="API Key"
            name="apiKey"
            type="password"
          />
          <TextField
            fullWidth
            id="keywords"
            label="Keywords"
            value={keywordInput}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            placeholder="Type and press enter to add keywords"
            variant="outlined"
            margin="normal"
            sx={{ marginBottom: 2 }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              listStyle: 'none',
              p: 0.5,
              margin: 0,
            }}
          >
            {keywords.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                onDelete={() => handleDeleteChip(keyword)}
                deleteIcon={
                  <IconButton size="small">
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
          <TextField
            margin="normal"
            fullWidth
            id="chatbotInstructions"
            label="Chatbot Instructions"
            name="chatbotInstructions"
            multiline
            rows={4}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/" variant="body2">
                {"Have an account? Log in"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
